export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export const param = object => {
    return new URLSearchParams(object).toString();
};

/**
 * Konwertuje obiekt FormData na plain object dla serializacji do JSON
 * @param formData
 * @return {{}}
 */
export const formDataToJson = formData => {
    const object = {};
    for (let pair of formData.entries()) {
        const key = pair[0];
        const value = pair[1];
        const isArray = key.endsWith('[]');
        const name = key.substring(0, key.length - (2 * isArray));
        const path = name.replaceAll(']', '');
        const pathParts = path.split('[');
        const partialsCount = pathParts.length;
        let iterationObject = object;
        for (let i = 0; i < partialsCount; i++) {
            let part = pathParts[i];
            let iterationObjectElement = iterationObject[part];
            if (i !== partialsCount - 1) {
                if (!iterationObject.hasOwnProperty(part) || typeof iterationObjectElement !== "object") {
                    iterationObject[part] = {};
                }
                iterationObject = iterationObject[part];
            } else {
                if (isArray) {
                    if (!iterationObject.hasOwnProperty(part)) {
                        iterationObject[part] = [value];
                    } else {
                        if (!Array.isArray(iterationObjectElement)) {
                            iterationObjectElement = iterationObject[part] = [iterationObjectElement];
                        }
                        iterationObjectElement.push(value);
                    }
                } else {
                    iterationObject[part] = value;
                }
            }
        }
    }
    return object;
};

/**
 * Wykonuje zapytanie REST
 * @param {string} method (GET|POST|PUT|DELETE)
 * @param {string} url
 * @param {object|null} data
 * @param {object} options
 * @return {Promise<any>}
 */
export const restRequest = async (method, url, data= null, options = {}) => {
    const response = await fetch(url, {
        method,
        mode: options.mode || 'cors',
        cache: 'no-cache',
        redirect: options.redirect || 'follow',
        credentials: options.credentials || 'same-origin',
        referrerPolicy: options.referrerPolicy || 'no-referrer-when-downgrade',
        headers: {
            'Content-Type': 'application/json',
            ...(options.headers || {}),
        },
        body: method === POST || method === PUT ? JSON.stringify(data) : void 0,
    });

    const contentType = response.headers.get('content-type');
    response.responseBody = await response.text();
    response.responseJSON = response.responseBody && contentType === 'application/json'
        ? JSON.parse(response.responseBody)
        : null;

    if (!response.ok) {
        throw response;
    }

    return contentType === 'application/json'
        ? response.responseJSON
        : response.responseBody;
};

export const restGet = (url) => restRequest(GET, url);
export const restUpdate = (url, data = {}) => restRequest(PUT, url, data);
export const restCreate = (url, data = {}) => restRequest(POST, url, data);
export const restDelete = (url) => restRequest(DELETE, url);


export const restSaveModel = (model, {createPath, updatePath, path, query = {}, paths = POLKURIER.paths, pk = 'id'}) => {
    // paths ??= POLKURIER.paths;
    // pk ??= 'id';
    if (model.id) {
        return restUpdate(
            paths.get(updatePath || path, {
                ...query,
                [pk]: model[pk],
            }),
            model,
        );
    }
    return restCreate(
        paths.get(createPath || path, {
            ...query,
            [pk]: '',
        }),
        model,
    );
};

/**
 *
 * @param {String} url
 * @param {FormData} formData
 * @returns {Promise<any>}
 */
export const postFormData = async (url, formData) => {
    const response = await fetch(url, {
        method: POST,
        cache: 'no-cache',
        credentials: 'same-origin',
        body: formData,
    });

    const responseData = response.headers.get('content-type') === 'application/json'
        ? await response.json()
        : await response.text();

    if (!response.ok) {
        response.responseJSON = responseData;
        throw response;
    }
    return responseData;
};

export class FileBlob {
    constructor({content, name, type, size}) {
        this.content = content;
        this.name = String(name);
        this.type = String(type);
        this.size = Number(size);
    }
}

/**
 * @param {String} url
 * @param {Object} options
 * @return {Promise<FileBlob>}
 */
export const getBlob = async (url, options = {}) => {
    const response = await fetch(url, {
        method: options.method || GET,
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: options.headers,
        body: options.method === POST || options.method === PUT ? JSON.stringify(options.data) : void 0,
    });

    if (!response.ok) {
        if (response.headers.get('content-type') === 'application/json') {
            response.responseJSON = await response.json();
        }
        throw response;
    }

    const [contentDispositionType, ...contentDispositionProps] = response.headers.get('content-disposition')?.split(';') ?? [];
    let name = 'unnamed';
    if (contentDispositionType === 'attachment') {
        let key, val;
        for (let prop of contentDispositionProps) {
            [key, val] = prop.trim().split('=');
            if (key.trim() === 'filename') {
                name = decodeURIComponent(val.trim());
                break;
            }
        }
    }

    const blob = await response.blob();
    return new FileBlob({
        name,
        content: blob,
        type: response.headers.get('content-type'),
        size: blob.size,
    });
};
